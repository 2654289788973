import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import CardActionArea from '@material-ui/core/CardActionArea';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import PropTypes from 'prop-types';
import Typography from '../Typography';

const useStyles = makeStyles({
  root: {
    // display: 'flex',
    flexGrow: 1,
    margin: '10px',
  },
  media: {
    margin: 'auto',
    marginTop: '50px',
    height: 150,
    width: 150,
    borderRadius: '20px',
  },
  content: {
    margin: '25px',
  },
  about: {
    paddingRight: '30px',
  },
  degree: {
    color: '#39A3FB',
  },
});

export default function PartnerCard(props) {
  const classes = useStyles();
  const {
    name, degrees, about, specialisation, image,
  } = props;
  return (
    <Paper className={classes.root} elevation={0}>
      <CardActionArea>
        <Grid container className={classes.root} spacing={2}>
          <Grid key={1} item xs={12} md={2}>
            <CardMedia
              className={classes.media}
              image={image}
              title="Contemplative Reptile"
            />
          </Grid>
          <Grid key={2} item xs={12} md={10}>
            <CardContent className={classes.content}>
              <Typography
                gutterBottom
                variant="h3"
                component="h2"
                color="primary"
              >
                {name}
              </Typography>
              <Typography gutterBottom variant="subtitle1">
                {degrees}
              </Typography>
              <Typography gutterBottom variant="subtitle1">
                {`Specialisation: ${specialisation}`}
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                component="p"
                style={{ textAlign: 'justify' }}
                className={classes.about}
              >
                {about}
              </Typography>
            </CardContent>
          </Grid>
        </Grid>
      </CardActionArea>
    </Paper>
  );
}

PartnerCard.propTypes = {
  name: PropTypes.string.isRequired,
  degrees: PropTypes.string.isRequired,
  about: PropTypes.string.isRequired,
  specialisation: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  image: PropTypes.any.isRequired,
};
