/* eslint-disable no-undef */
import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { useLocation } from '@reach/router';
import PartnerCard from './partner-card';
import Header from '../Header';
import Typography from '../Typography';
import MohitImage from '../../images/mohit.jpg';
import YatheenImage from '../../images/yatheen.jpg';


const data = [
  {
    id: 1,
    name: 'Mohit Bhagat',
    about: `
    Mr. Mohit Bhagat born on 2nd May 1992, is a co-founder of the firm. He is a member of The Institute of Chartered Accountants of India since 2015, he also qualified DISA (Information System Audit) in 2022 & completed certificate course on FAFD (Forensic Accounting & Fraud Detection) in 2021.He also attended course on IND-AS conducted by ICAI in 2022. He specializes in the field of Indirect Taxes. He graduated from St. Aloysius Evening College, Mangaluru in 2013 & completed his articleship with PLM & Associates. He worked extensively on Indirect Taxes & Assurance Services. Qualified in first attempt in 2015, Mr Bhagat, embarked on a new professional journey, with ICICI Bank as Credit Manager. After an enriching and successful career in banking sector, he left the organization in 2018 to add yet another feather to his cap, by starting his own practice. After gaining knowledge in banking sector, he, now specializes in Project Funding & GST.
        `,
    degrees: 'FCA, Certified Information System Auditor (DISA), Certified Forensic Auditor (FAFD),Certificate Course on IND-AS, B.Com',
    specialisation: 'Project Funding & Indirect Taxes',
    email: 'bhagatandkumar@gmail.com',
    image: MohitImage,
  },
  {
    id: 2,
    name: 'Yatheen Kumar',
    about: `Mr. Yatheen Kumar born on 4th Sep.1992, is a co-founder of the firm. He is a member of The Institute of Chartered Accountants of India since 2017. He has wide experience in the field of Direct Taxes, Statutory Bank Audit, Internal Audit & Project funding. He Graduated from St. Aloysius Evening College, Mangaluru in 2013 & did articleship in Kamath & Rau from 2012-2015, after which he was elevated as Audit Manager from 2015-2019. During his stint with Kamath & Rau, he worked extensively on Direct Taxes and Statuary Audit. He also handled Concurrent and Statutory Branch Audit of public and private sector banks, He was also the Chief Co-ordinator for Central Statutory Audit of one of the prominent private sector bank based in Mangaluru. He also lead the team for Internal audit of prominent Institute based in Mangaluru. Currently he specializes in Project Funding & Direct Taxes.
        `,
    degrees: 'FCA, B.Com',
    specialisation: 'Project Funding & Direct Taxes',
    email: 'bhagatandkumar@gmail.com',
    image: YatheenImage,
  },
];

const useStyles = makeStyles({
  header: {
    marginTop: '70px',
  },
  info: {
    padding: '50px',
  },
  table: {
    margin: '50px',
    maxWidth: '600px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
});
export default function Partners() {
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    if (location.search) {
      // eslint-disable-next-line no-undef
      window.scrollTo(0, 400);
    }
  });

  return (
    <>
      <Header />
      <Paper elevation={0}>
        <section>
          <Grid container className={classes.header} spacing={2}>
            <Grid key={1} item xs={12} md={12}>
              <Typography
                variant="h3"
                align="center"
                marked="center"
                style={{ paddingTop: '20px' }}
              >
                About Us
              </Typography>
            </Grid>
          </Grid>
        </section>
        <Typography
          gutterBottom
          variant="subtitle1"
          className={classes.info}
          style={{ textAlign: 'justify' }}
        >
          Founded in 2019, Bhagat & Kumar (B&K), is a Chartered Accountants
          firm, engaged into rendering multi-disciplinary services in the field
          of audit and assurance, taxation, project financing, advisory services
          etc. B&K is a professionally managed firm, comprising of people
          imbibed with values and ethos, along with, high degree of professional
          competency and expertise, in their professional arena.
          <br />
          Withstanding the contemporary world, in which all businesses operates,
          we are committed to provide an end to end client services, which
          caters to their specific requirements, with committed professionalism.
          Also, considering, the diverse businesses our clients are into, we are
          inclined to have a regular interactions with industries and other
          professionals in the field, to keep pace with the contemporary world
          so, as to cater to our client’s needs.
          <br />
          We take pride in working with a team of professional and highly
          efficient members, who not only takes ownership of their individual
          tasks, but also ensures, that the firm’s values are not compromised
          with, at the meagre cost of rendering services to the clients. Our
          values and ethos comes first, rather than short term individual goals.
        </Typography>
        <Typography variant="h4" align="left" style={{ paddingLeft: '50px' }}>
          Partners:
        </Typography>
        <Typography
          gutterBottom
          variant="subtitle1"
          className={classes.info}
          style={{ textAlign: 'justify' }}
        >
          <b>“BHAGAT & KUMAR” </b>
          was a dream, which was conceptualized by the co-founders- Mr. Mohit
          Bhagat & Mr. Yatheen Kumar, since their college days. As Dr. APJ Abdul
          Kalam has aptly said, “You have to dream, before the dreams comes
          true”. Hence, To add wings to their dreams, both the partners, left
          their lucrative corporate jobs & headed towards a new avenue, on 14th
          Feb 2019 -the day on which, B&K was formed.
        </Typography>
        <section>
          {data.map((row) => (
            <PartnerCard
              key={row.id}
              name={row.name}
              about={row.about}
              degrees={row.degrees}
              specialisation={row.specialisation}
              email={row.email}
              image={row.image}
            />
          ))}
        </section>
        <Typography variant="h4" align="left" style={{ paddingLeft: '50px' }}>
          Team
        </Typography>
        <Typography
          gutterBottom
          variant="subtitle1"
          className={classes.info}
          style={{ textAlign: 'justify' }}
        >
          We work with a team of highly dedicated, professional and committed
          members. Our team, has been instrumental in instilling a sense of
          loyalty and confidence among our clients, through their commitment,
          proficiency and professionalism. The clients, seeks out regularly, for
          the rich and enhancing experience, we possess in this field and also
          for the transparency with which we discuss client’s matters, till they
          are resolved to their complete satisfaction. Since, confidentiality is
          crucial in our field of work, clients are assured of complete secrecy,
          from our end, with regard to all their financial information. The
          trust and belief, reposed by our existing clients, has helped us to
          earn a reputable position.
        </Typography>
      </Paper>
    </>
  );
}